<template>
    <div id="alex-app" :class="[{ 'opened-menu': !['portrait'].includes($mq) && openedMenu }, { offline: !connState }, $mq]">
        <template v-if="isLocationLoaded">
            <template v-if="showWrongIP">
                <ErrorWrongIP></ErrorWrongIP>
            </template>
            <template v-if="showErrorDeviceConfig">
                <ErrorDeviceConfig></ErrorDeviceConfig>
            </template>
            <template v-else-if="showTrialEndScreen">
                <TrialEndScreen></TrialEndScreen>
            </template>
            <template v-else-if="expiredLicenseDate && showExpiredLicenseDate">
                <LicenseEndDateExpired></LicenseEndDateExpired>
            </template>
            <template v-else-if="(update_app_min || update_available) && showUpdateScreen">
                <UpdateApp :min="update_app_min" @updateLater="hidePopupUpdate"></UpdateApp>
            </template>
            <template v-else-if="employeeLogged && userHasValidConditionsVersion">
                <!-- if doesnt show nps or create user form, continue with app normally -->
                <transition name="rightToLeft">
                    <UserBoxPortrait v-if="showingUser && loaded" :auditor="true"></UserBoxPortrait>
                </transition>
                <template v-if="!showPopupType">
                    <div id="fullcontent" ref="fullcontent" :class="[{ 'hide-scrollbar': !['portrait'].includes($mq) }, { overlay: ['portrait'].includes($mq) && (showingUser || openedMenu || openedSubMenu) }]">
                        <router-view v-if="loaded" />
                    </div>

                    <template v-if="['portrait'].includes($mq)">
                        <MenuTopPortrait v-show="showingTop" v-if="loaded"></MenuTopPortrait>
                        <transition name="leftToRight">
                            <ToolsBoxPortrait v-if="openedMenu && loaded"></ToolsBoxPortrait>
                        </transition>
                        <transition name="upToDown">
                            <SubMenuToolsPortrait v-if="openedSubMenu && loaded"></SubMenuToolsPortrait>
                        </transition>
                    </template>
                    <template v-if="['landscape', 'desktop'].includes($mq)">
                        <MenuTop v-show="showingTop" v-if="loaded" :auditor="true"></MenuTop>
                        <MenuTools v-show="showingTools" v-if="loaded"></MenuTools>
                    </template>
                    <div class="pusherMessage" v-if="pusherMessage">
                        {{ popupPusher() }}
                    </div>
                </template>
            </template>
            <template v-else>
                <MultiSite @loginSite="loginWithSiteAuditor" @refresh="refresh" :sites="[]" :isAuditor="true"></MultiSite>
            </template>
        </template>
        <!-- If user is not logged -->
        <template v-else>
            <!-- Login local -->
            <MultiSite @loginSite="loginWithSiteAuditor" :sites="auditorLocations" :isAuditor="true"></MultiSite>
        </template>
    </div>
</template>

<script>
import MultiSite from '@/components/domain/login/multiSite'
import ErrorWrongIP from '@/components/layout/ErrorWrongIP'
import TrialEndScreen from '@/components/layout/TrialEndScreen'
import LicenseEndDateExpired from '../components/layout/LicenseEndDateExpired.vue'
import UpdateApp from '@/components/layout/UpdateApp'
import MenuTopPortrait from '@/components/layout/MenuTopPortrait'
import ToolsBoxPortrait from '@/components/layout/ToolsBoxPortrait'
import SubMenuToolsPortrait from '@/components/layout/SubMenuToolsPortrait'
import UserBoxPortrait from '@/components/layout/UserBoxPortrait'
import MenuTop from '@/components/layout/MenuTop'
import MenuTools from '@/components/layout/MenuTools'
import { uuid } from 'vue-uuid'
import EmptyPage from '@/components/layout/EmptyPage.vue'

export default {
    name: 'Auditor-Layout',
    components: {
        MultiSite,
        ErrorWrongIP,
        TrialEndScreen,
        LicenseEndDateExpired,
        UpdateApp,
        MenuTopPortrait,
        ToolsBoxPortrait,
        UserBoxPortrait,
        MenuTop,
        MenuTools,
        EmptyPage
    },
    data() {
        return {
            connState: true,
            showUpdateScreen: true,
            userHasValidConditionsVersion: true,
            loaded: false,
            key: 1,
            deferredPrompt: false,
            auditorLoaded: false
        }
    },
    computed: {
        auditorLogged() {
            return this.$store.getters['loginUser/getAuditor']
        },
        openedMenu() {
            return this.$store.state['menu_tools_is_expanded']
        },
        expiredLicenseDate() {
            const date = this.$store.getters['getLicenseEndDate']
            if (date) {
                const now = moment()
                const licenseDate = moment(date)
                return licenseDate.isValid() && now.isAfter(licenseDate)
            }
            return false
        },
        showExpiredLicenseDate() {
            return this.$store.getters['getShowExpiredLicense']
        },
        update_app_min() {
            var version = this.$store.getters.getForceUpdate
            if (version) {
                this.checkPopupUpdate(version)
            }
            return version
        },
        update_available() {
            var version = this.$store.getters.getUpdateAvailable
            if (version) {
                this.checkPopupUpdate()
            }
            return version
        },
        showWrongIP() {
            return this.$store.getters['login/getWrongIp']
        },
        showTrialEndScreen() {
            return this.$store.getters['login/getShowTrialEndScreen']
        },
        showErrorDeviceConfig() {
            if (this.$store.getters.getIsApp && this.$store.getters.getDateLimitation) {
                return !(this.$store.getters.getAutoTimeEnable && !this.$store.getters.getAirPlaneModeEnable)
            }
            return false
        },
        employeeLogged() {
            let employee_id = this.$store.getters['loginUser/getAuditorEmployee']
            let employee = employee_id ? this.$store.getters['employee/getEmployee'] : false

            if (employee_id && employee) {
                // this.loaded = true
                if (employee.is_admin) this.userHasValidConditionsVersion = true
                this.npsClosed = false
            }
            return this.userLogged ? this.$store.getters['employee/getEmployee'](this.userLogged) : false
        },
        showPopupType() {
            if (this.key && this.employeeLogged) {
                return false
            }
        },
        showingUser() {
            return this.$store.state['menu_user_is_visible']
        },
        openedSubMenu() {
            return this.$store.state['submenu_tools_is_expanded']
        },
        showingTop() {
            return this.$store.state['menu_top_is_visible']
        },
        showingTools() {
            return this.$store.state['menu_tools_is_visible']
        },
        pusherMessage() {
            return this.$store.getters.getPusherData
        },
        isLocationLoaded() {
            return this.$store.getters['login/getIsLoadedLocation']
        },
        auditorLocations() {
            const locations = this.$store.getters['loginUser/getLocations']
            //sort by location_name asc
            return locations.sort((a, b) => a.location_name.localeCompare(b.location_name))
        },
        userLogged() {
            return this.$store.getters['loginUser/getAuditorEmployee']
        },
        isLoaded() {
            return this.$store.getters['login/isLoad']
        },
        getToken() {
            return this.$store.getters['login/getToken']
        },
        connection() {
            return this.$store.getters['getConnState']
        }
    },
    created() {
        // this.loadAuditor()
    },
    mounted() {},
    methods: {
        hidePopupUpdate() {
            this.showUpdateScreen = false
            localStorage.setItem('askUpdateScreen', moment())
        },
        async validateConditionsVersion() {
            let user = this.$store.getters['loginUser/getUserInformation']
            log(user)
            if (user && user.is_admin) this.userHasValidConditionsVersion = true
            else {
                let result = true

                // TODO: remove
                const visible = false

                // add flag for production
                if (visible) {
                    // check if device has connection to validate legal conditions
                    if (fnCheckConnection()) {
                        const employee_id = this.$store.getters['loginUser/getLocalEmployee']
                        const user = employee_id ? this.$store.getters['employee/getEmployee'](employee_id) : false

                        // check if user is logged to validate legal version
                        if (user) {
                            const conditionsVersion = await this.$store.getters['login/getConditionsVersion']
                            const employeeConditionsVersion = user && user.legal_version ? user.legal_version : '0'

                            // if conditions are not equal, return false
                            result = conditionsVersion == employeeConditionsVersion

                            // show popup
                            if (!result) {
                                this.$store.dispatch('loginUser/showPopupConditions', { employee_id: this.employeeLogged.id })
                            }
                        }
                    }
                }

                this.userHasValidConditionsVersion = result
            }
            /* this.userHasValidConditionsVersion = true; */
        },
        popupPusher() {
            if (this.pusherMessage.message && this.userLogged) {
                this.$popup.alert({ message: this.pusherMessage.message })
                this.$store.commit('setPusherData', false)
            }
        },
        loginWithSiteAuditor(location) {
            const self = this
            this.loaded = false
            this.$overlay.close()
            this.$overlay.loading()
            this.$store
                .dispatch('loginUser/doLoginAuditor', {
                    mq: self.$mq,
                    location_id: location.location_id
                })
                .then(function (response) {
                    self.$store.commit('login/setIsLoadedLocation', true)
                    self.$overlay.close()
                    self.showSplashScreen()
                })
        },
        loadAuditor() {
            log('loadAuditor', this.auditorLoaded, this.isLocationLoaded, this.$store.getters['loginUser/getLoadingLoginAuditor'])
            if (!this.auditorLoaded && !this.isLocationLoaded && !this.$store.getters['loginUser/getLoadingLoginAuditor']) {
                this.auditorLoaded = true
                this.$overlay.loading()
                let self = this
                
                this.$store.dispatch('login/doLoginAuditor', { email: this.auditorLogged.email, password: this.auditorLogged.password }).then(function (response) {
                    self.$overlay.close()
                })
            }
        },
        load() {
            this.$overlay.close()
            log('load')
            if (this.isLoaded && !this.loaded) {
                this.$overlay.loading()
                this.$store.commit('setMediaQuery', this.$mq)

                // PWA
                window.addEventListener('beforeinstallprompt', (e) => {
                    e.preventDefault()
                    // Stash the event so it can be triggered later.
                    this.deferredPrompt = e
                })

                this.$bar.reset()

                let self = this

                var checkConnectionInterval = window.setInterval(function () {
                    log('VERIFICANDO CONEXION')
                    let conn = fnCheckConnection()

                    if (self.connState && !conn) {
                        self.alertOffline()
                    } else if (!self.connState && conn) {
                        self.$snackbar.close()
                    }

                    self.connState = conn
                    self.$store.commit('setConnState', conn)
                }, 10000)

                if (this.getToken) {
                    log(this.getToken)

                    /*                     this.$store.commit('setMoment')
                     */
                    let promises = []

                    /* self.$store.dispatch('employee/loadEmployees', { force: true }).then(function (response) {
                        self.$store.dispatch('employee/loadRoles').then(function (response2) {
                            const skip = !response || !response2 ? 'skip' : false
                            self.loaded = true
                        })
                    }) */
                    const tools = self.$store.getters['login/getConfig'].tools

                    if (tools.includes(7)) {
                        promises.push(self.$store.dispatch('audit/loadTemplates', {}))
                        promises.push(self.$store.dispatch('audit/loadActionPlanTemplates', {}))
                    }

                    if (tools.includes(2)) {
                        self.$store.dispatch('issues/loadTemplates', {}, { root: true })
                        const issuesStates = self.$store.getters['getIssueStates']
                        if (issuesStates) {
                            if (Object.keys(issuesStates).length > 0) {
                                log('>> LOAD INIT ISSUES', issuesStates)
                                const states = Object.keys(issuesStates)
                                states.forEach((stateId) => {
                                    log('>> ISSUE state', stateId)
                                    self.$store.dispatch(
                                        'issues/loadAll',
                                        {
                                            date: 'all',
                                            type: stateId,
                                            template_id: 'all',
                                            number_page: 1,
                                            number_items: 10
                                        },
                                        { root: true }
                                    )
                                })
                            }
                        }
                    }
                    if (Object.values(self.$store.getters['employee/getAllEmployees']).length <= 0) {
                        promises.push(self.$store.dispatch('employee/loadRoles', {}))
                        promises.push(self.$store.dispatch('employee/loadEmployees', { force: true }))
                    }

                    Promise.all(promises).then(function (response) {
                        self.$overlay.close()
                        self.loaded = true
                    })

                    self.$store.dispatch('login/loadReleases', {}).then(() => {
                        self.loaded = true
                    })
                } else if (this.$store.getters['loginUser/getLocalEmployee']) {
                    self.loaded = true
                }

                self.$store
                    .dispatch('syncronize')
                    .then(function (response) {})
                    .catch(function (error) {
                        logError(error)
                    })

                if (!this.$store.getters.getIsApp) {
                    log('COOKIE -> ', document.cookie)
                    if (!self.getCookie('usernameAndy')) {
                        document.cookie = 'usernameAndy=' + uuid.v4()
                    }
                    self.$store.commit('setUuid', self.getCookie('usernameAndy'))
                } else {
                    self.$store.commit('setUuid', window.device.uuid)
                }
            }
        },
        alertOffline() {
            this.$snackbar.offline({ message: this.$t('assets.no_signal'), closeable: true, duration: 5000 })
        },
        showSplashScreen() {
            const self = this
            const showSplashScreen = this.$store.getters['getShowSplashScreen']

            if (showSplashScreen) {
                this.$splashscreen.show()
                function sleep(ms) {
                    return new Promise((resolve) => setTimeout(resolve, ms))
                }
                sleep(3500).then(() => {
                    self.$store.commit('setShowSplashScreen', 0)
                    self.$splashscreen.hide()
                })
            }
        },
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) return parts.pop().split(';').shift()
        },
        refresh() {
            let self = this
            self.$store.dispatch('employee/loadEmployees', { force: true }).then(function (response) {
                self.$store.dispatch('employee/loadRoles').then(function (response2) {
                    self.load()
                })
            })
        }
    },
    watch: {
        employeeLogged: function () {
            this.validateConditionsVersion()
        },
        isLoaded: function () {
            // console.log("isLoaded", this.isLoaded)
            if (this.isLoaded) {
                this.load()
            } else {
                var self = this
                setTimeout(() => {
                    self.loadAuditor()
                }, 200)
            }
        },
        isLocationLoaded: function () {
            // console.log("isLocationLoaded", this.isLocationLoaded)
            if (this.isLocationLoaded) {
                this.load()
            } else {
                var self = this
                setTimeout(() => {
                    self.loadAuditor()
                }, 200)
            }
        },
        getToken: function () {
            // console.log("getToken", this.isLocationLoaded)
            if (this.isLocationLoaded) {
                this.load()
            } else {
                var self = this
                setTimeout(() => {
                    self.loadAuditor()
                }, 200)
            }
        },
        connection: function () {
            if (this.connection) {
                setTimeout(async () => {
                    if (this.connection === fnCheckConnection()) {
                        await this.$store.dispatch('syncronize')
                        //this.$store.dispatch('audit/loadTemplates', {})
                    }
                }, 2000)
            }
        }
    }
}
</script>

<style lang="scss">
#fullcontent.library-fullscreen {
    z-index: 43 !important;
}
#alex-app {
    background-color: $default-bg-color;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    position: relative;

    &.offline {
        // background-color: red;
    }
}

#fullcontent:not(.inLogin) {
    @include border-radius($default-border-radius);
    @include default-box-shadow();
    background-color: $default-bg-color;
    top: $menu-top-height + $alex-app-padding;
    right: $alex-app-padding;
    width: calc(100% - #{$menu-tools-min});
    height: calc(100% - #{$menu-top-height} - (#{$alex-app-padding} * 2));
    z-index: $z-content;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    // transition-duration: 0.4s;

    > #content {
        width: 100%;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
        position: relative;
        background-color: $default-bg-color;
        // transition-duration: 0.2s;
        transform: none;

        & > .content,
        & > .column {
            // height: calc(100% - #{$menu-aux-height});
            height: calc(100%);
            // padding-bottom: $menu-aux-height;
            overflow-x: hidden;
            overflow-y: auto;
        }

        & > .content.left,
        & > .column.left {
            float: left;
        }
        & > .content.right,
        & > .column.right {
            float: right;
        }

        & > .column {
            width: $column-width;
            max-width: $column-max-width;
            background-color: #fff;
            position: relative;
            z-index: $z-column;

            &.left {
                // @include box-shadow($h: -5px, $v: 0, $b: 10px, $s: 0px, $c: rgba(68,68,68,0.3));
                // border-right: 1px solid #eee;
            }
            &.right {
                // @include box-shadow($h: 5px, $v: 0, $b: 10px, $s: 0px, $c: rgba(68,68,68,0.3));
                // border-left: 1px solid #eee;
            }
        }
        & > .content {
            background-color: $default-bg-color;
            width: $content-width;
            min-width: calc(100% - #{$column-max-width});

            &.full {
                width: calc(#{$content-width} + #{$column-width});
            }
        }

        & > .column nav {
            @include background($size: 14px, $position: center right 6px, $image: img('right_dark.svg'));
            @include display-flex();
            @include align-items(flex-end);
            @include justify-content();
            @include flex-direction(column);
            padding-right: 30px;
            height: 35px;
            font-family: $text-light;
            text-align: right;
            color: #444;
            background-color: #fcfcfc;
            // background-color: #fff;
            // border-bottom: 1px solid #eee;

            &.close {
                background-image: img('close_dark.svg');
                background-size: 18px;
            }
        }

        & > .column .calendar-container,
        & > .column .filters {
            display: block;
            float: left;
            height: 100%;
            max-height: 50%;
        }

        & > .column .calendar-container {
            width: 100%;
            // background-color: #ddd;
            overflow: hidden;
            min-height: 260px;
            padding: 10px;
            float: none;
        }

        & > .column .filters {
            width: 80%;
            height: auto;
            margin: 0 10%;
            padding-top: 5px;
            padding-bottom: 60px;
            position: relative;

            .title {
            }

            input,
            label,
            .employee-search {
                width: 100%;
            }

            .filter-actions {
                @include display-flex();
                @include align-items();
                @include justify-content();
                @include flex-wrap($w: wrap);
                // position: absolute;
                // bottom: 10px;
                margin: 10px auto;
                width: 100%;

                .results {
                    color: $main-s50;
                }

                .clear-filter {
                    @include border-radius(3px);
                    display: block;
                    height: 40px;
                    border: 1px solid #dddddd;
                    background-color: #f2f2f2;
                    margin-top: 5px;
                    padding: 0 20px;
                }
            }
        }
    }

    // .pull-loader {
    //     flex-direction: row;
    //     -webkit-flex-direction: row;
    //     -ms-flex-direction: row;
    //     -mos-flex-direction: row;
    //     -o-flex-direction: row;
    //     justify-content: center;
    //     -webkit-justify-content: center;
    //     -ms-justify-content: center;
    //     height: 100%;
    //     width: 100%;
    //     background-color: transparent;
    //     background-color: red;

    //     .box {
    //         display: box;
    //         display: -webkit-box;
    //         display: -moz-box;
    //         display: -ms-flexbox;
    //         display: -webkit-flex;
    //         display: flex;
    //     }

    //     .plCircle,
    //     .plInner,
    //     .plCore {
    //         border-radius: 50%;
    //         -webkit-border-radius: 50%;
    //         -moz-border-radius: 50%;
    //         -ms-border-radius: 50%;
    //         flex: none;
    //         -webkit-flex: none;
    //         -moz-flex: none;
    //         -ms-flex: none;
    //         -o-flex: none;
    //     }

    //     .plCircle {
    //         align-self: center;
    //         -webkit-align-self: center;
    //         -moz-align-self: center;
    //         -o-align-self: center;
    //         -ms-align-self: center;
    //         align-items: center;
    //         -ms-align-items: center;
    //         justify-content: center;
    //         -ms-justify-content: center;
    //         height: 100px;
    //         width: 100px;
    //         background-color: #222;
    //     }
    //     .plInner {
    //         align-self: center;
    //         -ms-align-self: center;
    //         justify-content: center;
    //         -ms-justify-content: center;
    //         height: 50%;
    //         width: 50%;
    //         background-color: $default-bg-color;
    //         -webkit-animation-name: pullLoaderAnimate;
    //         -webkit-animation-duration: 500ms;
    //         -webkit-animation-direction: alternate;
    //         -webkit-animation-timing-function: ease-in-out;
    //         -webkit-animation-iteration-count: infinite;
    //     }
    //     .plCore {
    //         align-self: center;
    //         -ms-align-self: center;
    //         height: 25%;
    //         width: 25%;
    //         background-color: #222;
    //         -webkit-animation-name: pullLoaderCoreAnimate;
    //         -webkit-animation-duration: 1s;
    //         -webkit-animation-direction: alternate;
    //         -webkit-animation-timing-function: ease-in-out;
    //         -webkit-animation-iteration-count: infinite;
    //     }

    //     @include keyframes(pullLoaderAnimate) {
    //         to {
    //             height: 90%;
    //             width: 90%;
    //         }
    //     }
    //     @include keyframes(pullLoaderCoreAnimate) {
    //         to {
    //             height: 90%;
    //             width: 90%;
    //         }
    //     }
    // }

    // &.pulling {
    //     // background-color: red;

    //     > #content {
    //         opacity: 0.5;
    //     }
    // }
    // &.refresh {
    //     @include background($image: img('alexhead_04.svg'), $size: 5px, $color: $default-bg-color);
    //     animation-name: fullrefresh;
    //     animation-duration: 1.5s;

    //     > #content {
    //         opacity: 0.3;
    //         // transition-duration: 0.2s;
    //         // transform: translateY(100%);
    //     }
    // }
    // @include keyframes(fullrefresh) {
    //     0% {
    //         // background-color: $main;
    //         // background-size: 20px;
    //     }
    //     25% {
    //         background-color: $main;
    //         // background-size: 300px;
    //     }
    //     50% {
    //         background-size: 300px;
    //     }
    //     75% {
    //         background-color: $main;
    //         // background-size: 20px;
    //     }
    //     100% {
    //         background-color: $default-bg-color;
    //         background-size: 0px;
    //     }
    // }
}

.opened-menu {
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;

    #fullcontent {
        right: unset;
        left: $menu-tools-max;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#alex-app.portrait {
    #fullcontent {
        @include border-radius(0px);
        left: 0;
        right: 0;
        bottom: 0;
        top: calc(#{$menu-top-height} - 3px);
        width: 100vw;
        height: calc(100% - #{$menu-top-height} + 3px);
        overflow: auto;
        overflow-y: auto;
        box-shadow: none;
        background-color: $default-bg-color;

        &.overlay {
            box-shadow: none;
            filter: brightness(0.4);
            z-index: 50;
        }

        #content {
            height: 100%;
            // padding: 15px;
            overflow: auto;
            max-height: none;
            background-color: $default-bg-color;

            .content {
                // padding: 10px;
                padding: 8px;
                background-color: $default-bg-color;
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
</style>
