<template>
    <div id="trial-end">
        <div name="modal" class="fadeIn" id="overlay" :class="[$mq]">
            <div class="row">
                <div class="avatar" :style="{ backgroundImage: 'url(' + avatar + ')' }"></div>
            </div>
            <div class="row">
                <div id="andy"></div>
            </div>
            <div class="row">
                <div class="message" v-html="$t('lincense.expired')"></div>
            </div>
            <div class="row">
                <div class="contact" v-html="$t('trial.contact', { account: accountName })"></div>
            </div>
            <div class="row">
                <div class="action" @click="userLogout" v-html="$t('trial.return')"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LicenseEndDateExpired',
    computed: {
        avatar() {
            const location = this.$store.getters['loginUser/getLocalLocation']
            const avatar = location && typeof location.avatar !== 'undefined' ? location.avatar : ''
            return avatar
        },
        accountName() {
            const location = this.$store.getters['loginUser/getLocalLocation']
            const account_name = location && typeof location.account_name !== 'undefined' ? location.account_name : ''
            return account_name
        }
    },
    methods: {
        userLogout(event) {
            event.preventDefault()
            event.stopPropagation()

            const self = this
            const employee_id = this.$store.getters['loginUser/getLocalEmployee']

            this.$store
                .dispatch('loginUser/doLogout', {
                    employee: employee_id
                })
                .then(function (response) {
                    self.$store.commit('setShowExpiredLicense', false)
                })
                .catch(function (error) {
                    logError(error)
                    self.$store.commit('setShowExpiredLicense', false)
                })
        }
    }
}
</script>

<style lang="scss">
#trial-end {
    #overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background-color: #f4f2ed;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-overlay-default + 2;

        .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .avatar {
                @include opacity(0.8);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                width: 200px;
                height: $menu-top-tools-height;
                line-height: initial;
                display: inline-block;
                cursor: auto;
            }

            #andy {
                min-width: 250px;
                min-height: 250px;
                @include background($image: img('andy_kitchen.png'), $size: 250px, $position: center center);
            }

            .message {
                @include font-size(l);
                color: $main-dark;
                font-family: $text-bold;
                text-align: center;
            }

            .contact {
                @include font-size(sm);
                color: #000;
                font-family: $text;
                text-align: center;
            }

            .action {
                @include border-radius(3px);
                @include font-size(ml);
                font-family: $text-bold;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 9px 15px;
                background-color: $main-dark;
                width: 200px;
                height: 55px;
                color: $neutro-light;
                cursor: pointer;
            }
        }

        @include portrait-tablet {
            .row {
                .avatar {
                    width: 200px;
                    height: calc(#{$menu-top-tools-height} + 100px);
                }

                #andy {
                    min-width: 300px;
                    min-height: 300px;
                    background-size: 300px;
                }

                .message {
                    @include font-size(l);
                }

                .contact {
                    @include font-size(m);
                }

                .action {
                    width: 220px;
                    height: 75px;
                    @include font-size(l);
                }
            }
        }

        &.landscape,
        &.desktop {
            .row {
                .avatar {
                    width: 200px;
                    height: calc(#{$menu-top-tools-height} + 100px);
                }

                #andy {
                    min-width: 300px;
                    min-height: 300px;
                    background-size: 300px;
                }

                .message {
                    @include font-size(xl);
                }

                .contact {
                    @include font-size(ml);
                }

                .action {
                    width: 220px;
                    height: 75px;
                    @include font-size(xl);
                }
            }
        }
    }
}
</style>
