<template>
    <nav id="menu-top" :class="[{ 'selector-open': isSelectorOpen() || isOrderPopupOpen || isFullScreen() }, $mq]">
        <div v-if="!currentRouteIsHomeWidgets" class="title-menu">
            <h5 :id="setIdTitle(pageTitle)" class="page-title" @click="toCurrentRoute">{{ pageTitle }}</h5>
        </div>
        <div v-else class="title-menu">
            <!-- <h5 class="page-title" @click="toCurrentRoute">{{ pageTitle }}</h5> -->
        </div>

        <router-link class="customer-logo" :to="redirectPath('/home')" :style="{ backgroundImage: 'url(' + clientLogo + ')' }"></router-link>

        <div class="left">
            <div class="offline-icon" v-if="!conn && !printing"></div>
            <div class="printer-icon" v-else-if="printing" @click="openPrintQueue()"></div>
            <div class="sync-icon" v-if="showSync" @click.stop="openStatusPage"></div>

            <div class="user-menu" :class="{ logged: emp_id, expanded: !emp_id || isKitchenModeOn }" v-if="!auditor">
                <div v-if="isKitchenModeOn && emp_id" class="time">{{ currentTime }}</div>
                <div v-if="emp_id" @click="userConfig()" class="user-login">
                    <div class="user-avatar" v-if="user">
                        <img class="main-img" v-bind:src="avatar" />
                    </div>
                    <div class="user-info">
                        <div v-if="user" class="user-name">{{ user.name }} {{ user.surname }}</div>
                        <div class="location" v-if="locationData">{{ locationData.name }}</div>
                    </div>
                    <div v-if="emp_id" @click="userLogout" class="user-logout" :class="[{ active: emp_id }, loginUserClass]">
                        <svg>
                            <circle ref="svgCountdown" :class="counterClass" r="45%" cx="50%" cy="50%" />
                        </svg>
                        <v-idle class="hidden-idle" @idle="handleCounterIdle" @remind="handleCounterRemind" :events="['mousemove', 'keydown', 'mousedown', 'touchstart']" :reminders="remindTime" :duration="idleTime" />
                    </div>
                </div>
                <div class="login-session" v-else>
                    <div class="time">{{ currentTime }}</div>
                    <div class="user-login init" @click="initSession">{{ $t('login.init_session') }}</div>
                </div>
            </div>
            <div class="user-menu" :class="{ logged: emp_id, auditor: auditor }" v-else>
                <div v-if="emp_id" @click="toggleUser" class="user-login">
                    <div class="user-avatar" v-if="user">
                        <img class="main-img" v-bind:src="avatar" />
                    </div>
                    <div class="user-info">
                        <div v-if="user" class="user-name">{{ user.name }} {{ user.surname }}</div>
                        <div class="location" v-if="locationData">{{ locationData.name }}</div>
                    </div>
                    <div v-if="emp_id && !auditor" class="user-logout" :class="[{ active: emp_id }, loginUserClass]" @click="auditorLogout">
                        <svg>
                            <circle ref="svgCountdown" :class="counterClass" r="45%" cx="50%" cy="50%" />
                        </svg>
                        <v-idle class="hidden-idle" @idle="localLogout" @remind="handleCounterRemind" :events="['mousemove', 'keydown', 'mousedown', 'touchstart']" :reminders="remindTime" :duration="idleTime" />
                    </div>
                </div>
            </div>
        </div>

        <!-- <h5 @click="checkLocalStorage">adelante amigo</h5> -->
    </nav>
</template>

<script>
import Vue from 'vue'
import Vidle from 'v-idle'
import { EventBus } from '@/eventBus.js'
Vue.use(Vidle)

export default {
    components: {},
    data() {
        return {
            counterClass: {},
            loginUserClass: '',
            currentTime: '',
            isMobile: false,
            newTitle: ''
        }
    },
    props: {
        auditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        // KITCHEN MODE FEATURE FLAG
        kitchenModeFeatureFlag() {
            const kitchenModeFeatureFlag = this.$store.getters['login/getKitchenModeFeatureFlag']

            return kitchenModeFeatureFlag && kitchenModeFeatureFlag === 1 ? true : false
        },
        isKitchenModeOn() {
            return localStorage.getItem('kitchenMode') && localStorage.getItem('kitchenMode') === 'true'
        },
        currentRouteIsHomeWidgets() {
            const currentRoute = this.$route.path

            return currentRoute === '/homewidgets'
        },
        conn() {
            return this.$store.getters['getConnState']
        },
        idleTime() {
            return this.$store.getters['getTimeout']
        },
        remindTime() {
            return [this.idleTime, idle_last_time]
        },
        avatar() {
            if (!this.user.avatar.includes('//')) {
                return css_image_url + 'avatar/' + this.user.avatar
            } else {
                return this.user.avatar
            }
        },
        locationData() {
            return this.$store.getters['loginUser/getLocalLocation']
        },
        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        date() {
            return this.$store.getters['formatedDate']
        },
        time() {
            return this.$store.getters['formatedTime']
        },
        showAlex() {
            return this.$store.state['alex_is_visible']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.emp_id)

            if (employee) {
                return employee
            }
        },
        role() {
            if (this.user) {
                if (this.user.rol) {
                    return this.$store.getters['employee/getRol'](this.user.rol)
                }
            }
        },
        titleLink() {
            if (this.emp_id) {
                return this.$tc('menu_top.change_user', 1)
            } else {
                return this.$tc('menu_top.change_user', 2)
            }
        },

        clientLogo() {
            const location = this.$store.getters['login/getLocation']
            if (typeof location !== 'undefined') {
                return location.avatar
            } else {
                return ''
            }
        },

        pageTitle() {
            console.log(this.$route.name)
            if (this.$route.name === 'TimersProduct') {
                return this.newTitle ? this.newTitle : this.$t('page_title.' + this.$route.name)
            } else {
                return this.$t('page_title.' + this.$route.name)
            }
        },

        home() {
            return this.$store.getters['getHome'] ? this.$store.getters['getHome'] : '/home'
        },
        printing() {
            return this.$store.getters['label/getIsPrinting']
        },
        showSync() {
            return this.$store.getters['getSyncFlag']
        }
    },
    methods: {
        setIdTitle(nameTitle) {
            return 'page-title-' + nameTitle.toLowerCase().replace(/ /g, '-')
        },
        checkIfMobile() {
            this.isMobile = window.matchMedia('(max-width: 768px)').matches
        },
        toggleUser() {
            if (this.showingUser) {
                this.$store.commit('setMenuUserVisible', false)
            } else {
                this.$store.commit('setMenuUserVisible', true)
                this.$store.commit('setSubMenuToolsExpanded', false)
                this.$store.commit('setMenuToolsExpanded', false)
            }
        },
        userConfig() {
            this.$router.push({
                name: 'user'
            })
        },
        openTimeclock() {
            this.$popup.timeclock({
                list: this.$store.getters['employee/getClockEmployees']
            })
        },
        changeUserLogin() {
            var self = this
            var path = this.redirectPath(this.$route.path)
            this.$popup.login({
                list: this.$store.getters['employee/getLoginUsers'](0),
                callAfterAccess: function (data) {
                    self.$router.push(self.$root.redirectPath(path)).catch((err) => {
                        if (err.name == 'NavigationDuplicated') {
                            self.$router.go(self.$router.history.current)
                        }
                    })
                }
            })
        },
        initSession() {
            // var self = this
            // this.$popup.login({
            //     list: this.$store.getters['employee/getLoginUsers'](0),
            //     callAfterAccess: function (data) {
            //         self.$popup.close()
            //     }
            // })
            this.$store.commit('loginUser/setVisibleConnect', true)
        },
        // USER LOGOUT // EMPLOYEE LOGOUT
        userLogout(event) {
            event.preventDefault()
            event.stopPropagation()

            var self = this
            const employee_id = this.$store.getters['loginUser/getLocalEmployee']

            if (employee_id) {
                self.$store
                    .dispatch('loginUser/doLogout', {
                        employee: employee_id
                    })
                    .then((response) => {
                        if (response) {
                            const isKitchenModeOn = localStorage.getItem('kitchenMode') && localStorage.getItem('kitchenMode') === 'true'

                            if (self.kitchenModeFeatureFlag && isKitchenModeOn) {
                                self.$router.push('/homewidgets').catch((err) => {
                                    //     if (err.name == 'NavigationDuplicated') {
                                    //         self.$router.push('/reset' + self.redirectPath(self.home))
                                    //     }
                                })
                            } else {
                                self.$router.push(self.$root.redirectPath(self.home)).catch((err) => {
                                    if (err.name == 'NavigationDuplicated') {
                                        self.$router.push('/reset' + self.redirectPath(self.home))
                                    }
                                })
                            }
                        }
                    })
            }
        },
        localLogout(event) {
            let changesToSyncronize = false
            var self = this
            const employeeTranslations = {
                confirm: {
                    message: self.$tc('config.changes_sync') + ' ' + self.$tc('config.changes_sync_logout', changesToSyncronize, { number: changesToSyncronize }) + ' ' + self.$tc('config.changes_sync_logout2', changesToSyncronize) + ' ' + self.$tc('config.changes_sync_logout3'),
                    textSave: self.$t('popup.confirm.stay_conected'),
                    textCancel: self.$t('popup.cancel.close_session')
                }
            }
            this.$store.dispatch('getCountSyncItems').then((count) => {
                changesToSyncronize = count
                if (count > 0) {
                    employeeTranslations.confirm.message = changesToSyncronize > 0 ? employeeTranslations.confirm.message.replace('false', changesToSyncronize) : employeeTranslations.confirm.message
                    self.$popup.confirm({
                        message: employeeTranslations.confirm.message,
                        // title: self.$t('popup.confirm.title'),
                        textSave: employeeTranslations.confirm.textSave,
                        textCancel: employeeTranslations.confirm.textCancel,
                        callSave: function () {
                            self.$popup.close()
                        },
                        callCancel: function () {
                            self.$store.dispatch('login/logoutAuditorLocal')
                            self.$router.push('/audit')
                        }
                    })
                } else {
                    self.$store.dispatch('login/logoutAuditorLocal')
                    if (self.$route.path.includes('/audit')) return
                    self.$router.push('/audit')
                }
            })
        },
        auditorLogout(event) {
            event.preventDefault()
            event.stopPropagation()

            var self = this
            const employee_id = this.$store.getters['loginUser/getLocalEmployee']
            const location_id = this.locationData ? this.locationData.id : null
            let changesToSyncronize = false
            const employeeTranslations = {
                confirm: {
                    message: self.$tc('config.changes_sync') + ' ' + self.$tc('config.changes_sync_logout', changesToSyncronize, { number: changesToSyncronize }) + ' ' + self.$tc('config.changes_sync_logout2', changesToSyncronize) + ' ' + self.$tc('config.changes_sync_logout3'),
                    textSave: self.$t('popup.confirm.stay_conected'),
                    textCancel: self.$t('popup.cancel.close_session')
                },
                disconnect: {
                    message: self.$t('login.logout_auditor_desc', { user: self.user.name }),
                    textSave: self.$t('popup.local_logout.text_save'),
                    textCancel: self.$t('popup.local_logout.text_cancel')
                }
            }

            this.$store.dispatch('getCountSyncItems').then((count) => {
                changesToSyncronize = count
                if (count > 0) {
                    employeeTranslations.confirm.message = changesToSyncronize > 0 ? employeeTranslations.confirm.message.replace('false', changesToSyncronize) : employeeTranslations.confirm.message

                    self.$popup.confirm({
                        message: employeeTranslations.confirm.message,
                        // title: self.$t('popup.confirm.title'),
                        textSave: employeeTranslations.confirm.textSave,
                        textCancel: employeeTranslations.confirm.textCancel,
                        callSave: function () {
                            self.$popup.close()
                        },
                        callCancel: function () {
                            if (employee_id) {
                                self.$popup.disconnect({
                                    message: employeeTranslations.disconnect.message,
                                    textSave: employeeTranslations.disconnect.textSave,
                                    textCancel: employeeTranslations.disconnect.textCancel,
                                    callSave: function () {
                                        // self.$router.push('/login')
                                        self.$overlay.loading()
                                        self.$store.dispatch('login/logout', { employeeId: employee_id, location_id: location_id }).then(function () {
                                            self.$store.dispatch('login/clearLoginData').then(() => {
                                                self.$overlay.hide()
                                                if (false && process.env.VUE_APP_IS_APP == 'TRUE') {
                                                } else {
                                                    self.$router.push('/login').then(function () {
                                                        location.reload()
                                                    })
                                                }
                                            })
                                        })
                                    }
                                })
                            }
                        }
                    })
                } else {
                    if (employee_id) {
                        self.$popup.disconnect({
                            message: self.$t('login.logout_auditor_desc', { user: self.user.name }),
                            textSave: self.$t('popup.local_logout.text_save'),
                            textCancel: self.$t('popup.local_logout.text_cancel'),
                            callSave: function () {
                                // self.$router.push('/login')
                                self.$store.dispatch('login/logout', { employeeId: employee_id, location_id: location_id }).then(function () {
                                    self.$store.dispatch('login/clearLoginData').then(() => {
                                        if (false && process.env.VUE_APP_IS_APP == 'TRUE') {
                                        } else {
                                            self.$router.push('/login').then(function () {
                                                location.reload()
                                            })
                                        }
                                    })
                                })
                            }
                        })
                    }
                }
            })
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },

        getLetters(string) {
            return this.$root.getFirstLetters(string)
        },

        toCurrentRoute() {
            let currentPath = this.$route.path
            let aux = this.$route.path.split('/')[1]
            let parentPath = typeof aux !== 'undefined' ? '/' + aux : false

            if (parentPath && parentPath != currentPath) {
                this.$router.push({ path: parentPath })
            }
        },

        checkLocalStorage() {
            let local = localStorage

            // localStorage.removeItem("token");
            // localStorage.removeItem("location");
            // localStorage.removeItem("account");
            // localStorage.removeItem("config");
            // localStorage.removeItem("printer");
            // localStorage.removeItem("menu_items");
            // localStorage.removeItem("employee_id");
        },

        handleCounterIdle() {
            var self = this
            const employee_id = this.$store.getters['loginUser/getLocalEmployee']
            if (employee_id) {
                this.$store
                    .dispatch('loginUser/doLogout', {
                        employee: employee_id
                    })
                    .then(function (response) {
                        const kitchenMode = localStorage.getItem('kitchenMode') ? (localStorage.getItem('kitchenMode') === 'true' ? true : false) : false
                        if (kitchenMode) self.$router.push('/homewidgets')

                        // self.$router.push(self.$root.redirectPath('/home')).catch(err => {
                        //     if (err.name == 'NavigationDuplicated') {
                        //         self.$router.go(self.$router.history.current)
                        //     }
                        // })
                    })
            }
        },
        handleCounterRemind(time) {
            let self = this

            if (time == this.remindTime[0]) {
                self.loginUserClass = ''
                self.$refs.svgCountdown.style.animation = 'none'
            } else if (time == self.remindTime[1]) {
                self.loginUserClass = 'attention'
                self.$refs.svgCountdown.style.animation = 'idleCountdown ' + self.remindTime[1] + 's linear 1 forwards'
            }
        },
        openPrintQueue() {
            this.$router.push({ name: 'LabelQueue', params: { queue: 1 } }).catch((err) => {})
        },
        openStatusPage() {
            if (!fnCheckConnection()) {
                this.$popup.offlineAlert({
                    message: this.$t('sync.sync_error_offline')
                })
                return
            }
            if (this.$route.name != 'ConfigPage') this.$router.push({ name: 'ConfigPage', params: { page: 'status' } })
        },
        isSelectorOpen() {
            return this.$store.getters['isSelectPopupOpen']
        },
        isOrderPopupOpen() {
            return this.$store.getters['isOrderPopupOpen']
        },
        isFullScreen() {
            return this.$store.getters['library/getFullScreen']
        },
        getCurrentTime() {
            this.currentTime = moment().format('HH:mm')
        }
    },
    created() {
        this.getCurrentTime()
        setInterval(this.getCurrentTime, 1000)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkIfMobile)
    },
    mounted() {
        this.checkIfMobile()
        window.addEventListener('resize', this.checkIfMobile)
        EventBus.$on('update-title', (data) => {
            this.newTitle = data.mensaje
        })
    },
    beforeDestroy() {
        EventBus.$off('update-title')
    },
    watch: {
        isMobile(newValue, oldValue) {
            setTimeout(() => {
                const userLogin = document.querySelector('.user-login')
                const loginSession = document.querySelector('.login-session')

                if (newValue) {
                    const customerLogo = document.querySelector('.customer-logo')
                    const fullcontent = document.querySelector('#fullcontent')
                    const menuTop = document.querySelector('#menu-top')
                    const boxes = document.querySelector('.boxes')

                    customerLogo.style.display = 'none'
                    fullcontent.style.marginTop = '11px'
                    menuTop.style.width = 'unset'
                    // boxes.style.paddingTop = '0px';
                    userLogin.classList.add('btn-login-movil')
                    loginSession.classList.add('login-session-movil')
                } else {
                    userLogin.classList.remove('btn-login-movil')
                    loginSession.classList.remove('login-session-movil')
                }
            }, 500)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#menu-top {
    $width-user-menu: rem(275px);
    $width-user-menu-expanded: rem(300px);
    $width-customer-menu: rem(140px);
    $width-title-menu: rem(220px);
    $width-user-menu-expanded-logged: rem(350px);

    $min-width-user-menu: $width-user-menu;
    $min-width-title-menu: $width-title-menu;

    $max-width-title-menu: calc(100% - #{$min-width-user-menu} - #{$width-customer-menu});
    $max-width-customer-menu: calc(100% - #{$min-width-user-menu} - #{$width-customer-menu});
    $max-width-user-menu: $width-user-menu;
    $max-width-user-menu-expanded: $width-user-menu-expanded;
    $max-width-user-menu-expanded-logged: $width-user-menu-expanded-logged;

    @include display-flex();
    @include align-items(flex-start);
    @include justify-content(space-between);
    position: fixed;
    top: $alex-app-padding;
    right: $alex-app-padding;
    z-index: $z-menu-top;
    height: $menu-top-height;
    width: calc(100% - #{$menu-tools-min} - (#{$alex-app-padding} * 4));
    // transition-duration: 0.4s;

    &.selector-open {
        z-index: $z-menu-top - 2;
    }

    .auditor-sub-menu {
        position: absolute;
        z-index: $z-menu-tools-expanded;
        height: 100vh;
        top: 0;
        right: 0;
        width: 800px;
        background: red;
    }

    .title-menu,
    .customer-logo,
    .user-menu {
        height: 100%;
        display: inline-block;
    }

    .title-menu {
        width: $width-title-menu;
        max-width: $max-width-title-menu;
        padding: 0 $alex-app-padding;
        overflow: hidden;
        min-width: fit-content;

        .page-title {
            $space-adjust: rem(10px);
            @include text-ellipsis();
            @include border-top-radius($default-border-radius);
            @include default-box-shadow();
            @include width-fit-content();
            @include font-size(ml); // TODO 1.6rem (no pilla el punto)
            font-family: $text-sbold;
            color: $main-s50;
            padding: 0 12px;
            margin: 0;
            margin-top: $space-adjust;
            display: inline-block;
            max-width: 100%;
            height: calc(100% - #{$space-adjust});
            line-height: $menu-top-height - $space-adjust;
            background-color: $default-bg-color;
        }
    }

    .customer-logo {
        // No reemplazar las propiedades de background
        @include opacity(0.8);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
        // width: $width-customer-menu;
        // height: $menu-top-tools-height !important;
        max-width: 400px;
        height: calc(100% - 0.375rem);
        width: 100%;
        line-height: initial;
        display: inline-block;
        cursor: auto;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .left {
        @include display-flex();
        width: calc(#{$width-user-menu} + 50px);
        max-width: calc(#{$width-user-menu} + 50px);
        @include justify-content(flex-end);
        .printer-icon {
            @include display-flex();
            @include background($image: img('print_portrait.svg'), $size: 50px, $position: center center);
            height: 50px;
            width: 50px;
            min-width: 50px;
            margin-right: 0px;
            cursor: pointer;
            margin-top: -3px;

            @keyframes pulse {
                0% {
                    transform: scale(0.95);
                }

                70% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(0.95);
                }
            }
        }

        .sync-icon {
            @include display-flex();
            @include background($image: img('offline/sync.svg'), $size: 50px, $position: center center);
            height: 50px;
            width: 50px;
            min-width: 50px;
            margin-right: 0px;
            cursor: pointer;
            margin-top: -3px;

            @keyframes pulse {
                0% {
                    transform: scale(0.95);
                }

                70% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(0.95);
                }
            }
        }

        .user-menu {
            $user-size: $menu-top-tools-height;
            $user-size-padding: rem(6px);
            $user-subsize: $user-size - $user-size-padding;
            @include display-inline-flex();
            @include align-items();
            @include justify-content();
            width: $width-user-menu;
            max-width: $max-width-user-menu;
            margin-top: -3px;

            &.expanded {
                max-width: $max-width-user-menu-expanded;
                width: $width-user-menu-expanded;
            }

            &.logged.expanded {
                max-width: $max-width-user-menu-expanded-logged;
                width: $width-user-menu-expanded-logged;
            }

            .time {
                @include font-size('l');
                line-height: 50px;
                margin-right: 10px;
                font-weight: bold;
            }

            .login-session {
                width: 100%;
                display: inline-flex;
                @include font-size('l');
            }

            .user-login {
                @include interaction();
                @include display-inline-flex();
                @include align-items();
                @include justify-content(space-between);
                @include border-radius($default-border-radius);
                @include default-box-shadow($o: 0.3);
                // background-color: $default-content-color;
                background-color: #fff;
                width: calc(100%);
                max-width: calc(100% - 5px);
                height: $user-size;
                text-align: center;
                padding-right: $user-size-padding;
                font-family: $title-bold;

                &:hover {
                    background-color: hover(#fff);
                }

                &.unlogued {
                    width: auto;
                }

                &.init {
                    @include background($size: 36px, $image: img('login.svg'), $position: right 8px center);
                    font-weight: bold;
                    @include font-size('m');
                    width: 75%;
                    text-transform: uppercase;
                    justify-content: center;
                    padding-right: 35px;
                }

                .user-avatar {
                    padding: $user-size-padding / 2;
                    min-width: $user-size;
                    width: $user-size;
                    height: $user-size;

                    .main-img {
                        width: $user-subsize;
                        height: $user-subsize;
                    }
                }

                .user-info {
                    display: flex;
                    flex-direction: column;
                    // width: 100%;
                    width: calc(100% - #{$user-size-padding} - #{$user-subsize} - #{$user-size});
                    padding: 0 $user-size-padding;

                    .user-name,
                    .role {
                        @include text-ellipsis();
                        width: 100%;
                    }
                    .user-name {
                        @include font-size(m);
                        font-family: $text-bold;
                        height: auto;
                        color: $neutro-s90;
                    }
                    .location {
                        @include font-size(s);
                        @include background($size: 10px, $image: img('location_dark.svg'), $position: left center);
                        @include text-ellipsis();
                        padding-left: 12px;
                        font-family: $text-medium;
                        height: 15px;
                        line-height: 15px;
                    }
                }

                .user-logout {
                    @include interaction();
                    @include background($image: img('logout_main_t20.svg'), $size: $user-subsize - rem(5px), $color: $neutro, $position: 2.5px 2px);
                    @include border-radius(50%);
                    min-width: $user-subsize;
                    width: $user-subsize;
                    height: $user-subsize;
                    display: inline-block;
                    position: relative;

                    .hidden-idle {
                        display: none;
                    }

                    &.attention {
                        @include background($image: img('logout2_warning.svg'), $size: 22px, $position: center center);
                    }
                    &:hover {
                        background-color: hover($neutro);
                    }

                    svg {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0;
                        min-width: $user-subsize;
                        width: $user-subsize;
                        height: $user-subsize;
                        transform: rotateY(-180deg) rotateZ(-90deg);

                        circle {
                            stroke-dasharray: 110px;
                            stroke-dashoffset: 0px;
                            stroke-linecap: round;
                            stroke-width: 4px;
                            stroke: transparent;
                            fill: none;
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            // height: 34px;
                            // width: 34px;
                        }
                    }
                }

                .applyLogoLogin {
                    @include background($image: img('login.svg'), $size: $user-subsize - rem(5px), $color: $neutro, $position: 2.5px 2px);
                    background-color: unset;
                }

                .applyFont {
                    font-family: $title-bold;
                    font-size: 18px;
                    width: unset;
                    display: inline-block;
                    min-width: 165px;
                    text-align: center;
                }
            }

            .btn-login-movil {
                font-size: 20px !important;
                width: auto !important;
                padding-left: 22px !important;
                padding-right: 54px !important;
            }
            .login-session-movil {
                justify-content: right !important;
                padding-right: 17px !important;
            }

            .login-session-movil > .time {
                margin-top: 5px;
            }
        }
        .withoutSession {
            width: unset;
        }
        .auditor {
            width: calc(#{$width-user-menu} - 5rem);
            .user-login {
                justify-content: left;
                .user-info {
                    width: calc(100% - 2.625rem);
                }
            }
        }
        .offline-icon {
            @include display-flex();
            @include background($image: img('offline/no_wifi.svg'), $size: 40px, $position: center center);
            height: 40px;
            width: 50px;
            min-width: 50px;
        }
    }
    &.portrait {
        .title-menu {
            .page-title {
                visibility: hidden;
            }
        }
        .customer-logo {
            left: 0;
        }
    }
}

.opened-menu #menu-top {
    right: unset;
    left: $menu-tools-max;
}

@keyframes idleCountdown {
    0% {
        stroke: transparent;
        stroke-dashoffset: 0px;
    }
    3% {
        stroke: $progress;
    }
    50% {
        stroke: $progress;
    }
    100% {
        stroke: $error;
        stroke-dashoffset: 113px;
    }
}
</style>
